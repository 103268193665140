var App = (function () {
	'use strict';

    if (!window.console) {
        window.console = {log:function(){},error:function(){},warn:function(){},trace:function(){}};
    }

    console.log('%c__         __    _', 'font-family: monospace; color: #56C3D7; font-weight: bold');
    console.log('%c\\ \\   _   / /__ | |__  ___ ____  _ _', 'font-family: monospace; color: #56C3D7; font-weight: bold');
    console.log('%c \\ \\ / \\ / / _ \\| \'_ \\/ _ \\__  \\| | |', 'font-family: monospace; color: #56C3D7; font-weight: bold');
    console.log('%c  \\ \\ / \\ /| __/| |_) | __// _ || | | ', 'font-family: monospace; color: #56C3D7; font-weight: bold');
    console.log('%c   \\_/ \\_/ \\___||_.__/\\___|\\___|\\___/', 'font-family: monospace; color: #56C3D7; font-weight: bold');
    console.log(' ');
    console.log('%cSpreek jij onze taal?', 'font-weight: bold; color: black;');
    console.log('%cGa naar https://webeau.nl/vacatures', 'font-weight: bold; color: black;');
    console.log(' ');

    var app = {
        request: {
            is: (function () {
                var $html = $('html');

                return function (page) {
                    return $html.hasClass(page)
                }
            })(),
            pathname: location.pathname,
            segments: location.pathname.split('/'),
            hash: (function () {
                $(window).on('hashchange', function () {
                    app.request.hash = location.hash.substr(1);
                });

                return location.hash.substr(1)
            }()),
            param: function (name) {
                name = name.replace(/[\[\]]/g, "\\$&");
                var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
                    results = regex.exec(location.href);

                if (!results) return null;
                if (!results[2]) return '';

                return decodeURIComponent(results[2].replace(/\+/g, " "));
            }
        }
    };

    // Ajax token
    $.ajaxSetup({
        headers: {
            'X-CSRF-Token': $('meta[name="_token"]').attr('content')
        }
    });

    $(document).on('click', '[data-href]', function (e) {
        var $target = $(e.target);

        if ($target.is('a') && $target.attr('href')) {
            // Just follow a when clicked on it
            return;
        }

        var $a = $(this).find('a');

        if ($a.length) {
            var target = $a.attr('target');

            if (typeof target !== typeof undefined && target !== false) {
                window.open($a.attr('href'), target)
            } else {
                location.href = $a.attr('href');
            }
        }
    });

    $(document).on('keyup change', '.has-error :input', function () {
        $(this).closest('.has-error').removeClass('has-error');
    });

    // Remove token
    if (app.request.param('token')) {
        history.replaceState({}, document.title, location.pathname);
    }

    // Fire event to let know App has been loaded
    // Trigger event async via setTimeout
    setTimeout(function () {
        var e, d = document, type = 'app.loaded';

        if (window.Event) {
            try {
                e = new Event(type);
            } catch (exc) {
                e = d.createEvent('Event');
                e.initEvent(type, true, true);
            }

            d.dispatchEvent(e);
        } else {
            e = d.createEventObject();
            d.fireEvent('on' + type, e);
        }
    }, 2);

    return app;
}());