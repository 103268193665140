(function (app) {
    'use strict';

    if (!app.request.is('errors-404')) {
        return;
    }

    $('main').css('minHeight', $(window).height() - $('header').outerHeight() - $('footer').outerHeight());

}(App));
