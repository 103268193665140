(function (app) {
    'use strict';

    if (!app.request.is('special-home')) {
        return;
    }

    var module = {};

    $('.item-actie form').submit(function (e) {
        e.preventDefault();

        var $form = $(this);
        var $btn = $form.find('button:submit');

        // Loading state
        $btn.prop('disabled', true).data('html', $btn.html()).html('Verzenden... <i class="fa fa-spinner fa-pulse"/>');

        $.ajax({
            method: 'POST',
            url: '/aanmelding',
            data: $form.serialize(),
            success: function () {
                $form.replaceWith('<div class="alert alert-success">Je gegevens zijn verzonden!</div>');
            },
            error: function (jqXHR) {
                if (jqXHR.status === 403) {
                    var error = jqXHR.responseJSON;

                    if (error) {
                        $form.replaceWith('<div class="alert alert-info">' + error + '</div>');

                        return;
                    }
                }

                $btn.html('<i class="fa fa-warning"/> &nbsp; Verzenden mislukt');

                for (var i = 0; i < 4; i++) {
                    setTimeout((function (i) {
                        return function () {
                            $btn.html($btn.html() + '.');
                        }
                    })(i), i * 1000);
                }

                setTimeout(function () {
                    $btn.prop('disabled', false).html($btn.data('html'));
                }, (i+1) * 1000);
            }
        })
    });

    app.home = module;

}(App));
