(function (app) {
    'use strict';

    var module = {};

    module.menu = (function () {
        // Init menu

        return {
            open: function () {

            },
            close: function () {

            }
        };
    })();

    $('.slick-wrapper').slick({
        infinite: true,
        dots: true,
        adaptiveHeight: true,
        prevArrow: '<div class="slick-prev"><i class="fa fa-chevron-left"></i></div>',
        nextArrow: '<div class="slick-next"><i class="fa fa-chevron-right"></i></div>'
    });

    app.ui = module;

}(App));
